<template>
    <teleport to='body' v-if='impersonating && !impersonateDialogOpen'>
        <div v-draggable='{ cancel: "" }' id='impersonationIndicator' class='card'>
            <div id='text'>
                {{ $parse('אתה', 'את')}} צופה באפליקציה<br/>
                כ{{ impersonating.name }}
            </div>
            <div id='buttons'>
                <button class='material-icons-round' @click='impersonate()'>exit_to_app</button>
                <button class='material-icons-round' @click='setImpersonateDialog(true)'>swap_horiz</button>
            </div>
        </div>
    </teleport>
    <dialog-component v-if='isAdmin && impersonateDialogOpen' :open='true' @close='setImpersonateDialog(false)'>
        <template #title>מעבר למשתמש אחר</template>
        <label id='impersonateDialog'>
            {{ $parse('בחר', 'בחרי') }} את המשתמש ש{{ $parse('תרצה', 'תרצי') }} להתחזות אליו:
            <custom-select v-model='userToImpersonate' :options='userList' @change='impersonate' />
        </label>
    </dialog-component>
</template>

<script lang='ts'>
import { sendRequest } from "@/SharedChart/main";
import { State } from "@/ts/state/State";
import { computed, defineComponent, inject, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    props: {
        isAdmin: { type: Boolean, required: true },
    },
    setup(){
        const store = useStore<State>();
        const userList = ref([]);
        const userToImpersonate = ref('');
        onBeforeMount(async () => {
            try{
                const list = await sendRequest('/user/list');
                userList.value = list.body?.map((item: any) => [item.id, item.name]) ?? [];
            }catch(error){
                console.error(error);
            }
        });

        return {
            userList,
            userToImpersonate,
            impersonating: computed(() => store.state.auth0.impersonating),
            impersonateDialogOpen: inject('impersonateDialogOpen'),
            setImpersonateDialog: inject('setImpersonateDialog'),
            impersonate(userId: string){
                if(userId)
                    localStorage.setItem('impersonate', userId);
                else
                    localStorage.removeItem('impersonate');
                window.location.assign('/');
            }
        }
    }
})
</script>

<style lang='scss' scoped>
#impersonationIndicator {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 2000;
    cursor: grab;
    font-size: .8rem;
    text-align: center;

    &:active {
        cursor: grabbing;
    }

    & #text {
        padding: .4rem .8rem;
    }

    & #buttons {
        display: flex;
        background-color: #eaeaea;
        gap: 1px;

        & button {
            margin-top: 1px;
            width: 50%;
            padding: .2rem;
            font-size: 1.3rem;
            background-color: $swatchB;

            &:hover {
                background-color: $hoverB;
                cursor: pointer;
            }
        }
    }
}

#impersonateDialog {
    padding: 2rem 3rem 3rem;

    & select {
        width: 100%;
        margin-top: .5rem;
    }
}
</style>