
import { sendRequest } from "@/SharedChart/main";
import { State } from "@/ts/state/State";
import { computed, defineComponent, inject, onBeforeMount, ref } from "vue";
import { useStore } from "vuex";

export default defineComponent({
    props: {
        isAdmin: { type: Boolean, required: true },
    },
    setup(){
        const store = useStore<State>();
        const userList = ref([]);
        const userToImpersonate = ref('');
        onBeforeMount(async () => {
            try{
                const list = await sendRequest('/user/list');
                userList.value = list.body?.map((item: any) => [item.id, item.name]) ?? [];
            }catch(error){
                console.error(error);
            }
        });

        return {
            userList,
            userToImpersonate,
            impersonating: computed(() => store.state.auth0.impersonating),
            impersonateDialogOpen: inject('impersonateDialogOpen'),
            setImpersonateDialog: inject('setImpersonateDialog'),
            impersonate(userId: string){
                if(userId)
                    localStorage.setItem('impersonate', userId);
                else
                    localStorage.removeItem('impersonate');
                window.location.assign('/');
            }
        }
    }
})
